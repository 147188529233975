@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-height: 60px;
$footer-height: 70px;

.departure-date-side-panel-content {
	height: 100%;
	position: relative;

	.smart-dp-header {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
	}

	&__header {
		@extend %font-bold;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		font-size: 2.2rem;
		height: $header-height;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 15px;
		box-sizing: border-box;
		background: $grey-light;

		svg {
			height: 15px;
			width: 15px;
		}
	}

	&__loader {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0 auto;
		padding-top: 40px;
	}

	&__body {
		height: 100vh;
		background: white;
		overflow: hidden;
		box-sizing: border-box;
		padding-top: 60px;
	}

	&__footer {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: $footer-height;
		padding: 10px 20px;
		box-sizing: border-box;
		background: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);

		&--bordered {
			box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
			border-top: 1px solid $grey-medium;
		}
	}

	&__note {
		width: 60%;
		font-size: 1.1rem;

		.amount {
			font-size: 2.2rem;

			&__suffix {
				font-size: 1.2rem;
			}
		}
	}

	&__small-note {
		color: $grey-dark;
		font-size: 0.75rem;
	}

	&__cta {
		width: 40%;

		.button {
			height: 100%;
			min-height: 40px;
		}
	}
}
