@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-button {
	$this: &;
	position: relative;
	box-sizing: border-box;

	&__label {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $black;
		pointer-events: none; // fix empeche de saisir sur device ios

		@include gridle_state(md) {
			z-index: 1;
		}
	}

	&__input {
		@extend %font-bold;
		height: 50px;
		// the content is not centered on ios
		padding: 10px 10px 0 15px;
		box-sizing: border-box;
		border: 1px solid $input-border-color;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $input-border-radius;
		box-shadow: $input-shadow-box;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;
		position: unset !important; // popover put position relative and make disapear label
	}

	&__header {
		@extend %font-bold;
		font-size: 2rem;
		height: 60px;
		min-height: 60px;
		width: calc(100% - 40px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
	}

	&__header-icon {
		width: 20px;
	}

	&__drawer {
		background: $grey-light !important;
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;
			top: 6px;
			text-overflow: ellipsis;
			right: 0;
			display: inline;
			white-space: nowrap;
		}
	}

	&--with-icon {
		#{$this}__label {
			left: 40px;
		}

		#{$this}__input {
			padding-left: 50px;
		}

		#{$this}__icon {
			width: 18px;
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;

			svg {
				width: 18px;
			}
		}
	}

	.passengers-dounter-display {
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
