@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-fieldset {
	box-shadow: 0px 4px 7px #{$grey-dark + "29"};
	border-radius: 10px;
	background: #ffffff;

	@include gridle_state(md) {
		box-shadow: inset 0px 0px 0px 1px $grey-medium;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		border-bottom: 1px solid $grey-medium;
	}

	&__title {
		@extend %font-bold;
		font-size: 2rem;

		@include gridle_state(md) {
			font-size: 1.5rem;
		}
	}

	&__remove {
		@extend %link;
		font-size: 1.2rem;
		text-decoration: underline;
		cursor: pointer;
	}

	&__content {
		padding: 15px;
	}

	& .alert-message--error {
		margin-bottom: 0px;
	}
}
