@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-form {
	&__form {
		height: 100%;
		padding-top: 15px;
		@include gridle_state(md) {
			width: 340px;
			padding-top: unset;
		}
	}

	&__popover-body {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 0 10px;
		padding-bottom: 180px;
		@include gridle_state(md) {
			margin-bottom: 20px;
			padding: unset;
		}
	}

	&__add-bedroom {
		background: white;
		padding: 15px;
		border-radius: 10px;
		@extend %font-bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		color: $grey-medium;
		box-shadow: 0px 4px 7px #{$grey-dark + "29"};
		@extend %font-bold;
		font-size: 2rem;

		@include gridle_state(md) {
			font-size: 1.5rem;
			box-shadow: inset 0px 0px 0px 1px $grey-medium;
		}
	}

	&__add-bedroom-button {
		@extend %link;
		font-size: 1.2rem;
		text-decoration: underline;
		cursor: pointer;
	}

	&__validate-button {
		margin-top: auto;
		padding: 10px;
		box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
		background: #ffffff;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		@include gridle_state(md) {
			box-shadow: none;
			background: none;
			position: relative;
			margin: unset;
			padding: unset;
		}
	}
}
