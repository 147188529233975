@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.date-calendar-input {
	$this: &;

	position: relative;
	box-sizing: border-box;

	&__label {
		position: absolute;
		top: 15px;
		left: 15px;
		display: flex;
		align-items: center;
		transition: top 0.1s ease-out, font-size 0.1s ease-out;
		color: $black;
		pointer-events: none; // fix empeche de saisir sur device ios
	}

	&__input {
		@extend %font-bold;
		height: 50px;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 0; // the content is not centered on ios
		padding-left: 15px;
		box-sizing: border-box;
		border: 1px solid $input-border-color;
		font-size: 1.4rem;
		outline: none;
		appearance: none;
		border-radius: $input-border-radius;
		box-shadow: $input-shadow-box;
		-webkit-appearance: none; // delete top big border in ios
		display: flex;
		align-items: center;
		background: white;
	}

	&--touched {
		#{$this}__label {
			@extend %input-label-touched;
			top: 6px;
		}
	}

	&--error {
		#{$this}__label {
			@extend %font-bold;
			color: $color-error;
		}
	}

	&--with-icon {
		#{$this}__label {
			left: 50px;
			padding-left: 0;
		}

		#{$this}__input {
			padding-left: 50px;
		}

		#{$this}__icon {
			width: 18px;
			position: absolute;
			top: 0;
			left: 16px;
			bottom: 0;
			display: flex;
			align-items: center;

			svg {
				width: 18px;
			}
		}
	}

	&__calendar {
		width: 420px;

		.react-calendar__navigation__next2-button,
		.react-calendar__navigation__prev2-button {
			display: none;
		}

		.react-calendar__viewContainer {
			display: flex;
			justify-content: space-between;
		}

		.react-calendar__month-view__days__day--neighboringMonth {
			visibility: hidden;
		}

		.react-calendar__navigation__label {
			text-transform: capitalize;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
			background: none;
			border: none;
			outline: none;
		}

		.react-calendar__navigation {
			display: flex;
			align-items: center;
			margin-bottom: 16px;
		}

		.react-calendar__navigation__arrow {
			background: none;
			border: none;
			cursor: pointer;
			padding-left: 0;
			padding-right: 0;

			svg {
				height: 30px;
				width: 20px;
			}

			&:disabled {
				color: $grey-medium;
				cursor: not-allowed;
			}
		}

		.react-calendar__navigation__label__divider {
			display: none;
		}

		.react-calendar__navigation__label__labelText {
			@extend %font-bold;
			font-size: 18px;
			width: 50%;
		}

		.react-calendar__month-view__weekdays {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			height: 20px;
			box-sizing: border-box;
			border-top: 1px solid $grey-medium;
			border-bottom: 1px solid $grey-medium;
			margin-bottom: 10px;
			padding-left: 10px;
			padding-right: 10px;

			&__weekday {
				text-align: center;
				width: 50px;
				font-size: 1.3rem;
				color: $grey-dark;

				abbr {
					text-decoration: none;
				}
			}
		}

		.date-calendar-input-tile {
			padding-right: 10px;
			padding-left: 10px;

			&__tile {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				height: 50px;
				border: none;
				background: none;
				color: $black;
				font-size: 1.4rem;
				padding-top: 10px;
				padding-bottom: 10px;
				box-sizing: border-box;
				width: 50px;

				abbr {
					width: 30px;
					border-radius: 50%;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&[disabled] {
					pointer-events: none;
					color: $grey-medium;
				}
			}
		}

		.react-calendar__tile--range {
			&.react-calendar__tile--active {
				abbr {
					color: white;
					background: $active-color;
				}
			}
		}
	}
}
