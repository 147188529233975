@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-marketing-color: $black !default;
$smartdp-search-form-background-color: $color-primary !default;

.smartdp-immersive-search {
	em {
		font-style: italic;
	}

	&__hero {
		position: relative;
		height: 210px;

		@include gridle_state(md) {
			height: 396px;

			&-content {
				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				top: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 100%
		);
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 15px;

		@include gridle_state(md) {
			background: none;
			height: auto;
			bottom: initial;
			width: 1024px;
			align-items: center;
			position: initial;
		}
	}

	&__subtitle {
		@extend %subheading;
		margin-top: 5px;
	}

	&__form {
		.sdp-search-form {
			padding: 0 10px 20px;
			background: $smartdp-search-form-background-color;
			width: calc(100% - 20px);

			@include gridle_state(md) {
				border-radius: $border-radius-big;
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
				padding: 7.5px 10px;
				background: white;
				width: calc(1024px - 20px);
			}
		}

		@include gridle_state(md) {
			position: relative;
		}
	}
}
