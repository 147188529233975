@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-search-form {
	&__header {
		height: 60px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;
		background: $grey-light;
		z-index: 2; // fix header not visible on safari
		transform: translate3d(0, 0, 0);
	}

	&__back {
		display: flex;
		align-items: center;

		svg {
			height: 15px;
			width: 15px;
		}
	}

	&__title {
		@extend %font-bold;
		font-size: 2.2rem;
	}

	&__reset {
		min-width: 15px;
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-sizing: border-box;
		@include gridle_state(md) {
			justify-content: unset;
			flex-wrap: nowrap;
			gap: unset;
			flex: auto;
		}

		width: 100%;

		border-radius: $border-radius-big;

		#departureCity {
			width: 100%;

			@include gridle_state(md) {
				flex: 1.3;
				width: unset;
			}
		}

		#destinationResort {
			width: 100%;

			@include gridle_state(md) {
				flex: 1.3;
				width: unset;
			}
		}

		#travellersRooms {
			@include gridle_state(md) {
				flex: 1.3;
				width: unset;
			}
		}

		.advanced-select__icon,
		.date-calendar-input__icon,
		.travellers-room-button__icon {
			color: $grey-dark;
			width: 20px;

			svg {
				width: 20px;
				max-height: 20px;
			}
		}

		.advanced-select--error {
			.advanced-select__select {
				box-shadow: none;
				border: none;
			}
		}
	}

	&__field {
		margin-bottom: 10px;
		width: calc(50% - 5px);
		height: 45px;
		background: white;

		@include gridle_state(md) {
			margin-bottom: 0;
			min-width: 0;
			width: unset;
			flex: 1;
			border-left: 1px solid $grey-light;
		}

		&:first-of-type {
			border-left: none;
		}

		.advanced-select__select__value-container {
			.sdp-search-form__suggestion-picto {
				display: none;
			}
		}

		& label {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&--fullsize {
			width: 100%;

			@include gridle_state(md) {
				width: unset;
			}
		}
	}

	&__footer {
		width: 100%;
		@include gridle_state(md) {
			width: unset;
			flex: 0.9;
		}

		.button {
			letter-spacing: 0;
			text-transform: initial;
		}

		svg {
			width: 26px;
			height: 26px;
		}
	}

	&__suggestion {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		overflow-x: hidden;

		&-picto {
			margin-right: 10px;
			display: flex;
			align-items: center;

			svg {
				width: 15px;
				max-height: 15px;
			}
		}

		&-label {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			flex: 1 1 0%;
		}
	}

	&--error {
		@include gridle_state(md) {
			position: absolute;
			display: flex;
			justify-content: center;
			width: 1024px;

			&:before {
				border-bottom: 10px solid #ffe5e5;
				border-right: 10px solid transparent;
				border-left: 10px solid transparent;
				top: 10px;
				content: "";
				display: block;
				position: absolute;
			}
		}
	}

	.alert-message {
		margin-top: 10px;
		text-align: center;

		&__message {
			justify-content: center;
		}

		@include gridle_state(md) {
			margin-top: 20px;
		}
	}

	.advanced-select__label,
	.date-calendar-input__label,
	.travellers-room-button__label {
		top: 15px;
	}

	.date-calendar-input--touched .date-calendar-input__label,
	.advanced-select--touched .advanced-select__label,
	.travellers-room-button--touched .travellers-room-button__label {
		top: 5px;
	}

	.advanced-select__select {
		height: 45px;
	}

	.advanced-select__select__value-container {
		padding-top: unset;
		padding-bottom: unset;
	}

	.advanced-select__select__input-container {
		height: 30px;
		margin-top: 13px;
	}

	.advanced-select .advanced-select__select__control,
	.date-calendar-input__input,
	.travellers-room-button__input {
		border: none !important;
		box-shadow: none;
		font-size: 1.4rem;
		align-items: flex-end;
		min-height: 30px;
		height: 30px;
		line-height: 30px;
	}

	.advanced-select .advanced-select__select__control {
		display: initial;
	}

	.date-calendar-input__input,
	.advanced-select__select__single-value,
	.travellers-room-button__input {
		margin-top: 13px;
		width: 100%;
	}
}
