@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travellers-room-input {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;

	&__item {
		display: flex;
		justify-content: space-between;
	}

	&__label {
		display: flex;
		flex-direction: column;
		padding-right: 15px;

		&--note {
			@extend %text-secondary;
			font-size: 1rem;
		}
	}

	&__birdthdates {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__birdthdate {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 7px;
	}

	&__child-index {
	}
}
